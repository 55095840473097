import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./url";

const allApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}` }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["User", "ProPmt"], //refresh when it innvalidates
  endpoints(build) {
    return {
      createUser: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/app/register/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
      }),
      createOrder: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/orders/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "Order", id: arg.id }],
      }),
      getOtp: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/app/request-otp/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
      }),
      resetPassword: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/app/verify-otp/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
      }),
      createEnquiry: build.mutation({
        query: (createEnquiryData) => {
          return {
            url: `/api/enquiry/`,
            method: "POST",
            body: createEnquiryData,
            headers: {
              Accept: "application/json",
              // ...formdata.getHeaders(),
            },
          };
        },
        // invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
      }),
      fetchProPmtServices: build.query({
        query: ({ category } = {}) => {
          if (!category) {
            // Return an empty query when category is not provided
            return {
              url: `/api/propmtservice/`,
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                "Content-Type": "application/json",
              },
            };
          }
          return {
            url: `/api/propmtservice/?category=${category}`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json",
            },
          };
        },
        providesTags: (result = [], error) =>
          Array.isArray(result) && result.length
            ? [
              ...result.map(({ id }) => ({ type: "ProPmt", id })),
              "ProPmt",
            ]
            : ["ProPmt"],
      }),
      getPmtServiceDetails: build.query({
        query: ({ id }) => {
          return {
            url: `/api/propmtservice/${id}/`,
            method: "GET",
          };
        },
        providesTags: (result = [], error, arg) =>
          result
            ? [{ type: "ProPmt", id: result.id }, "ProPmt"]
            : ["ProPmt"],
      }),
      fetchProPmtCategory: build.query({
        query: () => {
          return {
            url: `/api/pmtcategory/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json",
            },
          };
        },
        providesTags: (result = [], error) =>
          Array.isArray(result) && result.length
            ? [
              ...result.map(({ id }) => ({ type: "ProPmt", id })),
              "ProPmt",
            ]
            : ["ProPmt"],
      }),
      fetchPmtCourses: build.query({
        query: () => {
          return {
            url: `/api/purchasedservices/my-services/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json",
            },
          };
        },
        providesTags: (result = [], error) =>
          Array.isArray(result) && result.length
            ? [
              ...result.map(({ id }) => ({ type: "ProPmt", id })),
              "ProPmt",
            ]
            : ["ProPmt"],
      }),
      getMycourseDetails: build.query({
        query: ({ id }) => {
          return {
            url: `/api/purchasedservices/${id}/my-service/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result
            ? [{ type: "ProPmt", id: result.id }, "ProPmt"]
            : ["ProPmt"],
      }),
      updateUser: build.mutation({
        query: (data) => {
          return {
            url: `/app/user/update/${data?.id}/`,
            method: "PUT",
            body: data,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json",
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "User", _id: arg._id },
        ],
      }),
    };
  },
});
const publicApi = createApi({
  reducerPath: "publicapi",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}` }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["ProPmtSlider"],
  endpoints(build) {
    return {
      fetchProPmtSlider: build.query({
        query: () => {
          return {
            url: `/api/pmtsliders/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
        },
        providesTags: (result = [], error) =>
          Array.isArray(result) && result.length
            ? [
              ...result.map(({ id }) => ({ type: "ProPmtSlider", id })),
              "ProPmtSlider",
            ]
            : ["ProPmtSlider"],
      }),
    };
  },
});

export const {
  useCreateUserMutation,
  useCreateEnquiryMutation,
  useGetOtpMutation,
  useResetPasswordMutation,
  useFetchProPmtServicesQuery,
  useFetchProPmtCategoryQuery,
  useCreateOrderMutation,
  useGetPmtServiceDetailsQuery,
  useFetchPmtCoursesQuery,
  useGetMycourseDetailsQuery,
  useUpdateUserMutation,
} = allApi;
export const {
  useFetchProPmtSliderQuery,
} = publicApi;

export { allApi, publicApi };
